import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

const MARKETPLACE_REGIONS_PREFIX = "marketplace_regions/fetch";
export const fetchMarketplaceRegionsType = createRequestActionTypes(MARKETPLACE_REGIONS_PREFIX);

export interface IMarketplaceRegion {
    id: number;
    name: string;
    remote_id: string;
    slug: string;
}

export interface IMarketplaceRegionsResponse extends IPaginatedResponse {
    results: IMarketplaceRegion[];
}

export const fetchMarketplaceRegions = (ctx: Pick<IFetchContext<IRPRequestMeta>, "meta">) => (dispatch: Dispatch) => {
    dispatch({type: fetchMarketplaceRegionsType.start});

    const marketplaceRegionsLink = apiLink.marketplace.region.list();
    return getRequest(ctx.meta, marketplaceRegionsLink)
        .then((marketplaceRegions: IMarketplaceRegionsResponse) => {
            dispatch({
                type: fetchMarketplaceRegionsType.success,
                result: marketplaceRegions.results
            });

            return marketplaceRegions;
        })
        .catch(
            catch404(async (err) => {
                notifyBugsnag(err, "marketplace endpoint 404", err.response ?? {});

                dispatch({
                    type: fetchMarketplaceRegionsType.success,
                    result: []
                });

                return [];
            })
        );
};
